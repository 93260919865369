import { FC } from 'react';
import { Box } from '@mui/material';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { DevProjectCard } from 'modules/theme/components/listing/cards/devProjectCard/components/card/DevProjectCard';
import { getDevProjectDetailUrl } from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/getDevProjectDetailUrl';
import {
    getAdvertisementSelectListItemEvent,
    getSelectPromotionPremiumEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/listing/advertisementListingEventsHelper';
import { mapIAdvertisementsListResultDevProjectToDsIDevProject } from 'components/page/advertisement/listing/helpers/mapper/result/devProject/mapIAdvertisementsListResultDevProjectToDsIDevProject';
import { IAdvertisementsListResultDevProject } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/IAdvertisementsListResultDevProject';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';

interface IDevProject {
    page: number;
    index: number;
    limit: number;
    devProject: IAdvertisementsListResultDevProject;
    advertisementGtmItem: IAdvertisementGtmListingItem;
}

export const DevProject: FC<IDevProject> = ({
    page,
    index,
    limit,
    devProject,
    advertisementGtmItem,
}) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);

    const onClick = () => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getAdvertisementSelectListItemEvent(advertisementGtmItem, index, page, limit),
        ];

        if (advertisementGtmItem.isTop) {
            events.push(getSelectPromotionPremiumEvent(advertisementGtmItem, index, page, limit));
        }

        if (advertisementGtmItem.isPremium) {
            events.push(getSelectPromotionPremiumEvent(advertisementGtmItem, index, page, limit));
        }

        gtmSendEvent(...events);
    };

    // TODO REFACTOR
    const detailUrl = getDevProjectDetailUrl(
        devProject.externalId,
        devProject.sefName,
        selectedCategories.map(
            (selectedCategory) => selectedCategory.name),
        undefined,
    );

    const dsDevProject =
        mapIAdvertisementsListResultDevProjectToDsIDevProject(devProject);

    return (
        <Box mb={4}>
            <DevProjectCard
                onClick={() => onClick()}
                devProject={dsDevProject}
                devProjectDetailUrl={detailUrl}
            />
        </Box>
    );
};
