import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { DevProject } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/devProject/DevProject';
import { Advertisement } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/advertisement/Advertisement';
import { ApiCategoriesType } from 'components/advertisementsFilter/modules/common/types/ApiCategoriesType';
import { IAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import {
    ExclusiveDevProjects
} from 'components/page/advertisement/listing/components/listing/partials/items/partials/promoServicesBox/partials/exclusiveDevProjects/ExclusiveDevProjects';
import { ValueOfRealEstate } from 'components/page/advertisement/listing/components/listing/partials/items/partials/promoServicesBox/partials/valueOfRealEstate/ValueOfRealEstate';
import { AdvertBox } from 'components/page/advertisement/listing/components/listing/components/advertBox/AdvertBox';
import { Ad } from 'components/ads/components/ad/Ad';
import { AdSlot } from 'components/ads/enums/AdSlot';

interface IItems {
    page: number;
    limit: number;
    devProjectsInitial: IDevProject[];
    items: IAdvertisementsListResult[];
    categoriesFromSearchFilter: ApiCategoriesType;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

const FIRST_ADV_BANNER_INDEX = 1;
const THIRD_ADV_DEV_PROJECTS_INDEX = 3;
const FIFTH_ADV_BANNER_INDEX = 5;
const NINTH_ADV_BANNER_INDEX = 9;
const THIRTEENTH_ADV_BANNER_INDEX = 13;
const SEVENTEENTH_ADV_PRICING_MAPS_INDEX = 17;
const LAST_ADV_BANNER_INDEX = 21;

export const Items: FC<IItems> = ({
    page,
    items,
    limit,
    devProjectsInitial,
    advertisementGtmItems,
    categoriesFromSearchFilter,
}) => {
    const advertisementItems = useMemo(() => {
        const listItems = [];

        items.forEach((item, itemIndex) => {
            const gtmItem =
                advertisementGtmItems.find((gtmItem) => gtmItem.id === item.advertisement?.id);

            if (itemIndex === FIRST_ADV_BANNER_INDEX) {
                listItems.push(
                    <AdvertBox key={itemIndex}>
                        <Ad slotDesktop={AdSlot.AD_DESKTOP_SCREEN_TOP} slotMobile={AdSlot.AD_MOBILE_TOP}/>
                    </AdvertBox>
                );
            }

            if (itemIndex === THIRD_ADV_DEV_PROJECTS_INDEX) {
                listItems.push(
                    <Box>
                        {devProjectsInitial.length > 0 &&
                            <ExclusiveDevProjects
                                devProjectsInitial={devProjectsInitial}
                            />
                        }
                    </Box>
                );
            }

            if (itemIndex === FIFTH_ADV_BANNER_INDEX) {
                listItems.push(
                    <AdvertBox key={itemIndex}>
                        <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_1} slotMobile={AdSlot.AD_MOBILE_LIST_1}/>
                    </AdvertBox>
                );
            }

            if (itemIndex === NINTH_ADV_BANNER_INDEX) {
                listItems.push(
                    <AdvertBox key={itemIndex}>
                        <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_2} slotMobile={AdSlot.AD_MOBILE_LIST_2}/>
                    </AdvertBox>
                );
            }

            if (itemIndex === THIRTEENTH_ADV_BANNER_INDEX) {
                listItems.push(
                    <AdvertBox key={itemIndex}>
                        <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_3} slotMobile={AdSlot.AD_MOBILE_LIST_3}/>
                    </AdvertBox>
                );
            }

            if (itemIndex === SEVENTEENTH_ADV_PRICING_MAPS_INDEX) {
                listItems.push(
                    <Box key={itemIndex} mb={4}>
                        <ValueOfRealEstate />
                    </Box>
                );
            }

            if (itemIndex === LAST_ADV_BANNER_INDEX) {
                listItems.push(
                    <AdvertBox key={itemIndex}>
                        <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_4} slotMobile={AdSlot.AD_MOBILE_LIST_4}/>
                    </AdvertBox>
                );
            }

            listItems.push(
                <Box key={itemIndex}>
                    {!!item.advertisement && (
                        <Advertisement
                            page={page}
                            index={itemIndex}
                            limit={limit}
                            hidePromoBoxes
                            isFirstAdvertisement
                            advertisement={item.advertisement}
                            advertisementGtmItem={gtmItem}
                            filterCategoriesForDevProject={
                                categoriesFromSearchFilter
                            }
                        />
                    )}

                    {!!item.devProject && (
                        <DevProject
                            page={page}
                            index={itemIndex}
                            limit={limit}
                            devProject={item.devProject}
                            advertisementGtmItem={gtmItem}
                        />
                    )}
                </Box>
            );
        });

        return listItems;
    }, [
        page,
        limit,
        items,
        devProjectsInitial,
        advertisementGtmItems,
        categoriesFromSearchFilter,
    ]);

    return (
        <>
            {advertisementItems}
        </>
    );
};