import { useCallback, useEffect, useMemo, useState } from 'react';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { fetchExclusiveProjects } from 'components/page/advertisement/listing/api/devProjects/fetchDevProjects';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
import {
    getOrderIndex
} from 'modules/gtmEvents/helpers/gtmHelper';
import {
    getAdvertisementListItem,
} from 'modules/gtmEvents/helpers/events/advertisement/listing/partials/advertisementListDataHelper';
import {
    IAdvertisementItem
} from 'modules/gtmEvents/interfaces/view/advertisement/partials/IAdvertisementItem';
import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { EItemListId } from 'modules/gtmEvents/enums/EItemListId';
import { EPromotionId } from 'modules/gtmEvents/enums/EPromotionId';
import { ECreativeSlot } from 'modules/gtmEvents/enums/ECreativeSlot';
import {
    mapIDevProjectToGtmListingItemMapper
} from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/mapIDevProjectToGtmListingItemMapper';
import { ECreativeName } from 'modules/gtmEvents/enums/ECreativeName';

export const LIMIT = 8;

interface IReturn {
    isLoading: boolean;
    fetchMore: () => void;
    devProjects: IDevProject[];
}

export const useDevProjects = (devProjectsInitial: IDevProject[] = []): IReturn => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [devProjects, setDevProjects] = useState<IDevProject[]>(devProjectsInitial);
    const [gtmItems, setGtmItems] = useState<IAdvertisementItem[]>([]);

    const itemsToSkip = useMemo(() => {
        return devProjects.length;
    }, [devProjects.length]);

    const fetchMore = useCallback(async () => {
        try {
            setIsLoading(true);
            const data: IDevProject[] = await fetchExclusiveProjects(
                LIMIT,
                itemsToSkip,
            );

            setDevProjects((currentProjects) => [...currentProjects, ...data]);

            setGtmItems(current => {
                const newGtmItems: IAdvertisementItem[] = data.map((devProject, index) => {
                    let orderIndex = index;
                    let currentPage = 1;
                    if (current.length >= LIMIT) {
                        orderIndex += current.length;
                        currentPage = Math.ceil(current.length / LIMIT);
                    }

                    return getAdvertisementListItem(
                        mapIDevProjectToGtmListingItemMapper(devProject),
                        getOrderIndex(orderIndex, currentPage, LIMIT),
                        'LT'
                    );
                });

                return [...current, ...newGtmItems];
            });

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [itemsToSkip]);

    useEffect(() => {
        if (!gtmItems.length) {
            return;
        }
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            {
                event: EGtmEvent.VIEW_ITEM_LIST,
                ecommerce: {
                    item_list_id: EItemListId.EXCLUSIVE_LISTINGS,
                    item_list_name: EItemListId.EXCLUSIVE_LISTINGS,
                    items: gtmItems.slice(gtmItems.length - LIMIT, gtmItems.length),
                },
            },
            {
                event: EGtmEvent.VIEW_PROMOTION,
                ecommerce: {
                    promotion_id: EPromotionId.TOP_OF_LIST,
                    promotion_name: EPromotionId.TOP_OF_LIST,
                    creative_slot: ECreativeSlot.CAROUSEL,
                    creative_name: ECreativeName.EXCLUSIVE,
                    items: gtmItems.slice(gtmItems.length - LIMIT, gtmItems.length),
                },
            },
        ];
        gtmSendEvent(...events);
    }, [gtmItems, gtmSendEvent]);

    useEffect(() => {
        if (!devProjects?.length && devProjectsInitial?.length) {
            setDevProjects(devProjectsInitial);
        }
    }, [devProjectsInitial, devProjects, setDevProjects]);

    return {
        devProjects,
        isLoading,
        fetchMore,
    };
};
