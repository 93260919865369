import { useCallback, useEffect } from 'react';
import { getMultipleIsFavourite } from 'components/favorite/api/getMultipleIsFavourite';
import { addToFavorite } from 'components/favorite/api/addToFavorite';
import {useLocalStorageItem} from 'modules/localStorage/hooks/useLocalStorageitem';
import useSession from 'modules/stores/session/useSession';
import { useAdvertisementsListingStore } from 'components/page/advertisement/listing/store/advertisementsListingStore';

export const useCheckMultipleFavorite = (
    advertisementIds: string[]
): void => {
    const { session } = useSession();
    const isLoggedIn = !!session;

    const favoriteIds = useAdvertisementsListingStore((store) => store.favoriteIds);
    const setFavoriteIds = useAdvertisementsListingStore((store) => store.setFavoriteIds);

    const {
        get: getStorageAdvId,
        remove: removeStorageAdvId,
    } = useLocalStorageItem('add-to-favorites');

    const fetchMultipleIsFavourite = useCallback(async (): Promise<
        string[]
    > => {
        if (!isLoggedIn || !advertisementIds?.length) {
            return;
        }

        try {
            if (getStorageAdvId() !== null) {
                await addToFavorite(getStorageAdvId());
            }
        } catch (error) {
            console.warn(error.message);
        } finally {
            setFavoriteIds([
                ...favoriteIds,
                getStorageAdvId(),
            ]);
            removeStorageAdvId();
        }

        try {
            const { data } = await getMultipleIsFavourite(advertisementIds);

            setFavoriteIds(data);
        } catch (error) {
            console.warn(error.message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, advertisementIds, getStorageAdvId, removeStorageAdvId]);

    useEffect(() => {
        fetchMultipleIsFavourite();
    }, [fetchMultipleIsFavourite]);
};
