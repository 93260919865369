import { FC } from 'react';
import { Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import MappinOutlineIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';

interface ICtaBtn {
    url: string;
    text: string;
}

export const CtaBtn: FC<ICtaBtn> = ({ url, text }) => {
    return (
        <Link href={url}>
            <Stack flexDirection={'row'} columnGap={0.5} alignItems={'center'}>
                <Svg
                    icon
                    component={MappinOutlineIcon}
                    width={16}
                    height={16}
                />
                <Text variant="body2" underLine noWrap>
                    {text}
                </Text>
            </Stack>
        </Link>
    );
};
