import { FC } from 'react';
import { Box } from '@mui/material';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { ListingHeader } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/ListingHeader';
import { Pagination } from 'components/page/advertisement/listing/components/listing/partials/pagination/Pagination';
import { ZeroResults } from 'components/page/advertisement/listing/components/listing/partials/zeroResults/ZeroResults';
import { DEFAULT_LIMIT } from 'components/page/advertisement/listing/constants/pagination/DefaultLimit';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { usePagination } from 'components/page/advertisement/listing/hooks/pagination/usePagination';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { GtmEvents } from 'components/page/advertisement/listing/components/listing/partials/gtmEvents/GtmEvents';
import {
    FavoriteAdvertisements
} from 'components/page/advertisement/listing/components/listing/partials/favoriteAdvertisements/FavoriteAdvertisements';
import { Items } from 'components/page/advertisement/listing/components/listing/partials/items/Items';

interface IAdvertisementsListing {
    breadcrumbs: IBreadcrumb[];
    devProjectsInitial: IDevProject[];
    advertisements: IAdvertisementsList;
    searchParams: Record<string, string>;
    filterDataFromUrl: FilterDataFromUrlType;
    pagination: IAdvertisementListingFilterPagination;
    initialSearchFilterData: IInitialSearchFilterData;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

export const AdvertisementsListing: FC<IAdvertisementsListing> = ({
    pagination,
    breadcrumbs,
    searchParams,
    advertisements,
    filterDataFromUrl,
    devProjectsInitial,
    advertisementGtmItems,
    initialSearchFilterData,
}) => {
    const {
        prevHref,
        nextHref,
        itemHref,
        currentPage,
    } = usePagination();

    if (!advertisements.results?.length) return (
        <Box pb={4}>
            <ZeroResults />
        </Box>
    );

    return (
        <>
            <GtmEvents
                currentPage={currentPage}
                searchParams={searchParams}
                advertisements={advertisements}
                advertisementGtmItems={advertisementGtmItems}
            />
            <FavoriteAdvertisements/>
            <Box pb={4}>
                <ListingHeader
                    pagination={pagination}
                    breadcrumbs={breadcrumbs}
                    searchTitle={advertisements.title}
                    filterDataFromUrl={filterDataFromUrl}
                    totalCount={advertisements.totalCount}
                    initialSearchFilterData={initialSearchFilterData}
                    advertisementsCount={advertisements.results.length}
                />
            </Box>
            <Box pb={4}>
                <Items
                    page={currentPage}
                    limit={DEFAULT_LIMIT}
                    devProjectsInitial={devProjectsInitial}
                    advertisementGtmItems={advertisementGtmItems}
                    categoriesFromSearchFilter={
                        filterDataFromUrl.categories?.map((category) => category.name)
                    }
                    items={advertisements?.results}
                />
            </Box>

            {advertisements.results?.length > 0 &&
                <Box pb={4}>
                    <Pagination
                        prevHref={prevHref}
                        nextHref={nextHref}
                        itemHref={itemHref}
                        currentPage={currentPage}
                        totalCount={advertisements.totalCount}
                    />
                </Box>
            }
        </>
    );
};
