import { FC, useEffect } from 'react';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    getAdvertisementViewItemListEvent,
    getPremiumAdvertisementsViewItemListEvent,
    getTopAdvertisementsViewItemListEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/listing/advertisementListingEventsHelper';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { DEFAULT_LIMIT } from 'components/page/advertisement/listing/constants/pagination/DefaultLimit';
import {
    setViewItemListAdvertisementsRealEstateListingEvents
} from 'components/page/realEstate/livemonitorEvents/helpers/events/eventsHelper';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';

interface IGtmEvents {
    currentPage: number;
    searchParams: Record<string, string>;
    advertisements: IAdvertisementsList;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

export const GtmEvents: FC<IGtmEvents> = ({
    currentPage,
    searchParams,
    advertisements,
    advertisementGtmItems
}) => {
    usePageViewEvent(searchParams);

    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    useEffect(() => {
        if (!advertisementGtmItems?.length) {
            return;
        }

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getAdvertisementViewItemListEvent(
                advertisements.title,
                advertisementGtmItems,
                currentPage,
                DEFAULT_LIMIT
            ),
        ];

        const topAdvertisementGtmItems = advertisementGtmItems.filter(
            (advertisementGtmItem) => advertisementGtmItem.isTop
        );

        const premiumAdvertisementGtmItems = advertisementGtmItems.filter(
            (advertisementGtmItem) => advertisementGtmItem.isPremium
        );

        if (topAdvertisementGtmItems.length > 0) {
            events.push(
                getTopAdvertisementsViewItemListEvent(
                    topAdvertisementGtmItems,
                    currentPage,
                    DEFAULT_LIMIT
                )
            );
        }

        if (premiumAdvertisementGtmItems.length > 0) {
            events.push(
                getPremiumAdvertisementsViewItemListEvent(
                    premiumAdvertisementGtmItems,
                    currentPage,
                    DEFAULT_LIMIT
                )
            );
        }

        gtmSendEvent(...events);

        setViewItemListAdvertisementsRealEstateListingEvents(
            livemonitorSendEvent,
            advertisementGtmItems.map(adv => adv.id),
            'plt-stats'
        );
    }, [advertisementGtmItems, gtmSendEvent, currentPage, livemonitorSendEvent, advertisements.title]);

    return null;
};