import { FC } from 'react';
import useLoading from 'modules/stores/loading/useLoading';
import { Paginator } from 'modules/theme/components/paginator/Paginator';
import { MAX_PAGES } from 'modules/advertisementsFilter/query/constants/pagination/pages/max/maxPages';
import { MAX_ADVERTISEMENTS } from 'modules/advertisementsFilter/query/constants/pagination/skip/max/maxSkip';
import { DEFAULT_LIMIT } from 'modules/advertisementsFilter/query/constants/pagination/limit/default/defaultLimit';

interface IPagination {
    prevHref: string;
    nextHref: string;
    totalCount: number;
    currentPage: number;
    isDisabled?: boolean;
    itemHref: (page: number) => string;
}

export const Pagination: FC<IPagination> = ({
    prevHref,
    nextHref,
    itemHref,
    totalCount,
    isDisabled,
    currentPage,
}) => {
    const { start: startLoading} = useLoading();

    const totalPageCount =
        totalCount >= MAX_ADVERTISEMENTS
            ? MAX_PAGES
            : Math.ceil(totalCount / DEFAULT_LIMIT);

    return (
        <Paginator
            showScrollToTopBtn
            prevHref={prevHref}
            nextHref={nextHref}
            itemHref={itemHref}
            onClick={startLoading}
            isDisabled={isDisabled}
            currentPage={currentPage}
            totalPageCount={totalPageCount}
        />
    );
};
