import { FC, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import { ADVERTISEMENT_DETAIL } from 'modules/route/routes';
import { useFavorite } from 'components/favorite/hooks/useFavorite';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { EFavoriteActions } from 'components/favorite/enums/EFavoriteActions';
import {
    getAdvertisementAddToWishListListItemEvent,
    getAdvertisementSelectListItemEvent,
    getSelectPromotionPremiumEvent, getSelectPromotionTopEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/listing/advertisementListingEventsHelper';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { useAdvertisementsListingStore } from 'components/page/advertisement/listing/store/advertisementsListingStore';
import { AdvertisementCard } from 'modules/theme/components/listing/cards/advertisementCard/components/card/AdvertisementCard';
import { PromoServicesBox } from 'components/page/advertisement/listing/components/listing/partials/items/partials/promoServicesBox/PromoServicesBox';
import {
    IAdvertisementsListResultAdvertisement
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/IAdvertisementsListResultAdvertisement';
import {
    mapIAdvertisementsListResultAdvertisementToDsIAdvertisement
} from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/mapIAdvertisementsListResultAdvertisementToDsIAdvertisement';

interface IAdvertisement {
    index: number;
    page: number;
    limit: number;
    hidePromoBoxes?: boolean;
    isFirstAdvertisement?: boolean;
    advertisementGtmItem: IAdvertisementGtmListingItem;
    advertisement: IAdvertisementsListResultAdvertisement;
    filterCategoriesForDevProject: (EMainCategory | ESubCategory)[];
}

export const Advertisement: FC<IAdvertisement> = ({
    page,
    index,
    limit,
    advertisement,
    hidePromoBoxes,
    isFirstAdvertisement,
    advertisementGtmItem,
    filterCategoriesForDevProject,
}) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const favoriteIds = useAdvertisementsListingStore((store) => store.favoriteIds);

    const {
        onAddOrDeleteFromFavorite,
        loginModal: favoriteLoginModal,
        isFavorite,
        isLoading: isLoadingFavorite,
    } = useFavorite(
        advertisement.id,
        favoriteIds.includes(advertisement.id),
    );

    const onClick = useCallback(() => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getAdvertisementSelectListItemEvent(advertisementGtmItem, index, page, limit),
        ];

        if (advertisementGtmItem.isTop) {
            events.push(getSelectPromotionTopEvent(advertisementGtmItem, index, page, limit));
        }

        if (advertisementGtmItem.isPremium) {
            events.push(getSelectPromotionPremiumEvent(advertisementGtmItem, index, page, limit));
        }

        gtmSendEvent(...events);
    }, [index, page, limit, gtmSendEvent, advertisementGtmItem]);

    const dsAdvertisement = useMemo(() => {
        return mapIAdvertisementsListResultAdvertisementToDsIAdvertisement(
            advertisement,
            filterCategoriesForDevProject,
            isFavorite,
            isFirstAdvertisement,
        );
    }, [
        isFavorite,
        advertisement,
        isFirstAdvertisement,
        filterCategoriesForDevProject,
    ]);

    const detailUrl = useMemo(() => {
        return `${AppConfigService.getNehnutelnostiUrl()}${ADVERTISEMENT_DETAIL(
            advertisement.id,
            advertisement.sefName
        )}`;
    }, [
        advertisement.id,
        advertisement.sefName,
    ]);

    const onFavoriteClick = useCallback(() => {
        onAddOrDeleteFromFavorite(
            isFavorite
                ? EFavoriteActions.DELETE_FROM_FAVORITE
                : EFavoriteActions.ADD_TO_FAVORITE
        );

        gtmSendEvent(
            getResetEcommerceEvent(),
            getAdvertisementAddToWishListListItemEvent(
                advertisementGtmItem,
                index,
                page,
                limit
            )
        );
    }, [
        page,
        limit,
        index,
        isFavorite,
        gtmSendEvent,
        advertisementGtmItem,
        onAddOrDeleteFromFavorite,
    ]);

    return (
        <>
            <Box mb={4}>
                <AdvertisementCard
                    onClick={onClick}
                    detailUrl={detailUrl}
                    advertisement={dsAdvertisement}
                    isFavoriteDisabled={isLoadingFavorite}
                    onFavoriteClick={() => onFavoriteClick()}
                />
                {favoriteLoginModal}
            </Box>
            {!hidePromoBoxes &&
                <PromoServicesBox index={index}/>
            }
        </>
    );
};
