import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const SubTitle: FC = () => {
    return (
        <Text variant={'body2'} mobileVariant={'body3'}>
            Developerské projekty na&nbsp;Slovensku
        </Text>
    );
};
